import React from "react";

function TermsandCondition() {
  return (
    <div className="privacy">
      <strong>Terms & Conditions</strong>
      <p>
        By accessing and using the ParkQwik web site located at www.parkqwik.com
        or otherwise availing yourself of the services, products, and
        information offered by ParkQwik Management, through the Web Sites,
        including joining the ParkQwik Frequent Parker Program, making
        reservations and facilitating the payment for your parking, you agree to
        be bound by the Terms and Conditions (hereinafter “Terms and Conditions”
        or “Agreement”) in effect at the time of each such access, purchase, or
        other use.
      </p>
      <p>
        You also acknowledge that we may, from time to time, in its sole
        discretion and without notification to you, change these Terms and
        Conditions. You should review the Terms and Conditions each time you
        visit or use the Web Sites. If you do not agree to these Terms and
        Conditions, do not use the site.
      </p>
      <strong>Overview</strong>
      <p>
        ParkQwik provides visitors to our Web Site with online access to
        information regarding our products and services, including, without
        limitation, the Frequent Parker Program, the booking of advance
        reservations for parking, and the ability to pay for parking.
      </p>
      <strong>Licence and Access to the Web Sites</strong>
      <p>
        ParkQwik grants you a limited, non-transferable license to access and
        use for non-commercial purposes the Web Site. You may only use the Web
        Site to make reservations, join the Frequent Parker Program, and/or
        learn about our products and services and shall not use the Web Site for
        any other purpose, including without limitation, to make any
        speculative, false, or fraudulent reservation or any reservation in
        anticipation of demand. This license does not include any resale or
        commercial use of the Web Site or its content; any derivative use of the
        Web Site or its content; or any use of data mining, robots, or similar
        data gathering and extraction tools. You may not frame or utilize
        framing techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of ParkQwik
        and our affiliates without our prior express written consent.
      </p>
      <p>
        We make no guarantees regarding the availability of the Web Site or the
        Services referenced in the Web Site. Furthermore, we reserve the right,
        within our sole discretion, to discontinue the Web Site or Services
        and/or modify the contents of the Web Site or the terms of the Services
        as we see fit, including but not limited to the pricing, airport parking
        facilities, and other information and/or functionality. You agree that
        we will not be liable to you for any such discontinuance or modification
        of the Web Site or the Services. Any rights not expressly granted by
        these Terms and Conditions are reserved by ParkQwik.
      </p>
      <strong>Restrictions on Your Use</strong>
      <p>
        All content within the Web Site and any materials made available on
        these pages for downloading are the property of ParkQwik and/or its
        affiliates or other third parties. The Web Site and portions of the Web
        Site are protected by copyright and trademark laws. This Agreement does
        not grant any license to modify or alter the materials on the Web Site
        that are viewed, downloaded, or otherwise accessed by you. You shall
        keep intact all proprietary notices, including copyright notices,
        contained on any downloadable materials. You may not input or upload to
        the Web Site any information that contains any viruses, Trojan horses,
        worms, spiders, time bombs, or other computer programming routines that
        are intended to damage, detrimentally interfere with, surreptitiously
        intercept, or expropriate any system, the Web Site or their contents or
        that infringes the intellectual property rights of any other party. You
        may not use any device, software, or routine to interfere or attempt to
        interfere with the proper working of the Web Site. You may not take any
        action that imposes an unreasonable or disproportionately large load on
        the system providing the Web Site. You shall be solely liable for any
        damages resulting from any infringement of copyright, trademark, or
        other proprietary right, or any and all harm resulting from your use of
        the Web Sites.
      </p>
      <strong>Age and Account Responsibility</strong>
      <p>
        If you use the Web Site, you are responsible for maintaining the
        confidentiality of your account information and your password. You agree
        to accept responsibility for all activities that occur under your
        account or password. You represent that you are of sufficient legal age
        to use the Web Site and to create binding legal obligations for any
        liability you may incur as a result of the use of the Web Site. You
        understand that you are financially responsible for all uses of the Web
        Site by you and those using your login information.
      </p>
      <strong>Disclosure of Content Monitoring</strong>
      <p>
        Although we have no obligation to monitor any information provided to
        the Web Sites by its users, including without limitation, your personal
        information, we reserve the right to monitor, retain, and disclose such
        information if required to do so by law or in a good faith belief that
        such disclosure is reasonably necessary to comply with legal
        requirements, to respond to claims that any information violates the
        rights of third parties, or to protect the rights, property, or personal
        safety of ParkQwik, its users, or the public.
      </p>
      <p>
        You understand that in order to promote the safety of our passengers,
        our employees, and the general public, as well as the security of our
        facilities, ParkQwik may conduct video and audio surveillance of any
        portion of its premises at any time, including both inside the ParkQwik
        shuttles and of the road and surroundings outside the shuttles. You
        hereby give your consent to such video and audio surveillance at any
        time ParkQwik may choose. You hereby release ParkQwik from all
        liability, claims, demands, actions, suits, damages, and expenses,
        including but not limited to claims for negligence or infringement of
        privacy rights, associated with the use of video and audio surveillance.
      </p>
      <strong>Intellectual Property Ownership</strong>
      <p>
        Any and all intellectual property rights associated with the Web Site,
        including, without limitation, any inventive concepts, know-how,
        publicity rights, trademarks, trade-dress, trade secrets, copyrights,
        and patents (“Intellectual Property”) are the sole property of ParkQwik,
        or third parties. Except as otherwise expressly authorized by this
        Agreement, you may not copy, reproduce, modify, lease, loan, sell,
        create derivative works from, upload, transmit, or distribute the
        Intellectual Property of the Web Site in any way without ParkQwik or the
        appropriate third party’s prior written permission. Except as provided
        herein, ParkQwik does not grant to you any express or implied rights to
        ParkQwik’s or any third party’s Intellectual Property. Any rights
        associated with the content of the Web Sites, which is transmitted,
        downloaded, or otherwise processed by ParkQwik, shall be retained by the
        owner of the content, if any, and may be protected by applicable
        copyright, trademark, or other laws.
      </p>
      <strong>Termination</strong>
      <p>
        You agree that ParkQwik, in its sole discretion, may terminate your use
        of the Web Site, and remove and discard any content within the Web Site,
        for any reason. You agree that any termination of your access to the Web
        Site under any provision of this Agreement may be effected without prior
        notice. Further, you agree that ParkQwik shall not be liable to you or
        any third party for any termination of your access to the Web Site.
      </p>
    </div>
  );
}
export default TermsandCondition;
