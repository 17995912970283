import React from "react";

export default function Refund(){
    return(
        <div className="privacy">
        <strong>Refund Policy</strong>
        <p>We strive to have one of the most customer-friendly change/cancel policies in the travel industry. We understand that life happens, plans change, and you may need to cancel or modify a reservation. This page covers our policies for such situations.
</p>
<strong>Reservation Modifications/Cancellations</strong>
<p>If you need to modify or cancel a pending reservation for any reason, no problem! Simply find your reservation and make the necessary changes or cancel it. Note that you can also make changes or cancel via our mobile apps.
</p>
<p>If your reservation was prepaid, you will be refunded the original payment. If you are modifying the reservation, you will then be charged any new amount due.</p>
<p>If you need some help, just let us know. Find your reservation and email the facility's email address listed on it. You can also find this on your confirmation email. Please include the following information:
</p>
<li>Reservation number</li>
<li>If prepaid, last 4 digits on the credit card used</li>
<li>If prepaid, name on the credit card</li>
<br/>
<strong>Early Returns</strong>
<p>If you prepaid for a reservation and returned early, just let us know. We'll refund the difference.
</p>
<p>Find your reservation and email the facility's email address listed on it. You can also find this on your confirmation email. Let us know that you left early. Please include the following information:
</p>
<li>Reservation number</li>
<li>If prepaid, last 4 digits on the credit card used</li>
<li>If prepaid, name on the credit card</li>
<br/>
<strong>Late Returns</strong>
<p>If you prepaid for a reservation and returned late, the cashier will charge you the difference at checkout.</p>
<strong>Double Booking</strong>
<p>If you prepaid for a reservation, forgot about it, and ended up paying at the lot, we'll take care of it.
</p>
<p>Find your original reservation and email the facility's email address listed on it. You can also find this on your confirmation email. Let us know that you paid twice for the same stay. Please include the following information:
</p>
<li>Original reservation Number</li>
<li>Last 4 digits on the credit card used to pay for the original reservation</li>
<li>Name on the credit card used to pay for the original reservation</li>


        </div>
    )
}