import React from 'react'
import Form from '../components/FormDel'
import Download from '../components/Download'
function DataDeletion() {
  return (
    <div className='delete'>
      <Form/>
      <Download/>
    </div>
  )
}

export default DataDeletion
